<template>
  <CRow>
    <CModal
        :title="
        submitType === 'Update' ? $lang.usertitle.crud.edit : $lang.usertitle.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        :hide-footer="true"
        color="primary"
    >
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
          err_msg
        }}
      </CAlert>
      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>
          <div
              class="form-group"
              :class="{ 'form-group--error': $v.usertitle.category.$error }"
          >
            <label class="form__label"
            >{{ $lang.usertitle.form.category }}
              <required_span/>
            </label>
            <v-select
                :options="groupCategories"
                v-model="usertitle.category"
            ></v-select>
            <small class="error" v-if="$v.usertitle.category.$error && !$v.usertitle.category.required">{{
                $lang.usertitle.validation.required.category
              }}</small>
          </div>
          <div
              class="form-group"
              :class="{ 'form-group--error': $v.usertitle.title.$error }"
          >
            <label class="form__label"
            >{{ $lang.usertitle.form.title }}
              <required_span/>
            </label>
            <input
                :placeholder="$lang.usertitle.form.title"
                class="form-control"
                v-model="usertitle.title"
            />

            <small class="error" v-if="$v.usertitle.title.$error && !$v.usertitle.title.required">{{
                $lang.usertitle.validation.required.title
              }}</small>
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton
              type="submit"
              size="sm"
              color="primary"
              :disabled="submitted"
          >
            <CIcon name="cil-check-circle"/>
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.usertitle.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton
                    color="primary"
                    v-on:click="createTableRow"
                    v-if="checkPermission('create-user-title')"
                    style="margin-right: 10px"
                >
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf" v-on:click="exports(self, 'pdf', module, 'UserTitles')">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data" :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="4">
                <div class="form-group">
                  <label class="form__label">{{ $lang.usertitle.form.category }}</label>
                  <v-select :options="groupCategories" v-model="usertitle.category"></v-select>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="form-group" style="margin-top: 30px">
                  <CButton type="submit" size="sm" color="primary" @click="applyFilter()" v-c-tooltip="$lang.common.filter.button.filter">
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton color="danger" size="sm" @click="resetFilter()" v-c-tooltip="$lang.common.filter.button.cancel">
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>
          <CAlert
              color="success"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert>
          <v-server-table :columns="columns" url="/groups/category-title/list" :options="options" ref="myTable" @loaded="exports(self, 'Excel', module, 'UserTitles')">
            <template #actions="data">
              <CButton color="success" v-c-tooltip="$lang.buttons.general.crud.edit" v-on:click="editTableRow(data.row.id)" v-if="checkPermission('edit-user-title')">
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton color="danger" v-c-tooltip="$lang.buttons.general.crud.delete" v-on:click="deletePopup(data.row.id)" v-if="checkPermission('delete-user-title')">
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {UserTitles} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import VueToasted from "vue-toasted";
import required_span from "../../components/layouts/general/required-span";
import BeforeFilter from "../BeforeFilterOpen";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "UserTitle",
  mixins: [Mixin],
  components: {
    required_span,
    BeforeFilter,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "test",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      groupCategories: [],
      CountryModalOptions: [],
      self: this,
      module: UserTitles,
      usertitle: {
        title: "",
        category: "",
      },
      columns: ["category", "title"],
      data: [],
      options: {
        headings: {
          category: this.$lang.usertitle.table.category,
          title: this.$lang.usertitle.table.title,
        },
        editableColumns: ["category", "title"],
        sortable: ["category", "title"],
        filterable: ["country", "title"],
      },
    };
  },
  validations: {
    usertitle: {
      title: {
        required
      },
      category: {
        required,
      },
    },
  },
  created() {
    if (
        this.checkPermission("edit-user-title") === true ||
        this.checkPermission("delete-user-title") === true
    ) {
      this.columns.push("actions");
    }
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    store.commit("showLoader", false); // Loader Off
    this.getList();
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getList() {
      let self = this;
      self.userList = "/group-category-types/list";
      axios.get(self.userList)
          .then((response) => {
            let responseData = response.data;
            responseData.data.map(function (value, key) {
              self.groupCategories.push({value: value.id, label: value.title});
            });
            self.groupCategories = this.removeDuplicateValues(self.groupCategories);
          });
    },
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.usertitle.isActive = 1;
      } else {
        self.usertitle.isActive = 0;
      }
    },
    applyFilter() {
      let self = this;
      const reqObj = {
        category: self.usertitle.category.value,
      };
      store.commit("showLoader", true); // Loader Off
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      let self = this;
      store.commit("showLoader", true); // Loader Off
      self.usertitle.category = {};
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.usertitle.title = "";
      self.usertitle.category = "";
      self.largeModal = true;
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              const responseData = response.data.data;
              self.usertitle.title = responseData.title;
              self.usertitle.category = {
                value: responseData.categoryId,
                label: responseData.category,
              };
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                const responseData = response.data;
                if (responseData.code === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = responseData.message;
                  self.dismissCountDownS = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.err_msg = data;
            self.dismissCountDown = 10;
            self.submitted = false; //Enable Button
          });
        }
      });
    },
    onSubmit() {
      let self = this;
      this.$v.usertitle.$touch();
      // console.log(this.$v.usertitle.$invalid);
      if (this.$v.usertitle.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              title: self.usertitle.title,
              category: self.usertitle.category.value,
            };
            axios
                .post(this.updateUrlweb(this.module, self.editId), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                }).catch(function (error) {
              let data;
              if (error.response.data.error) {
                data = error.response.data.error.toString();
              } else {
                data = error.response.data.message;
              }
              self.err_msg = data;
              self.dismissCountDown = 10;
              self.submitted = false; //Enable Button
            });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              title: self.usertitle.title,
              category: self.usertitle.category.value,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                    self.submitted = false; //Enable Button
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                    self.submitted = false; //Enable Button
                  }
                }).catch(function (error) {
              let data;
              if (error.response.data.error) {
                data = error.response.data.error.toString();
              } else {
                data = error.response.data.message;
              }
              self.err_msg = data;
              self.dismissCountDown = 10;
              self.submitted = false; //Enable Button
            });
          }, 500);
        }
      }
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
